import React from "react";
import { SEO, DynamicScript } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class PaymentPlansPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          ogUrl={"https://www.salon833.com/payment-plans/"}
        />
        <Grid
          className={"custom-page-payment-plans custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"clwvensiodnmbtmn"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column className={"zxanypfzbhasklnr"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__b538102c-0ce5-47ea-885a-13649b3b99c2"}
                >
                  <div>
                    <DynamicScript
                      type={"application/javascript"}
                      delay={200}
                      body={
                        "(function (w, d, s, o, f, js, fjs) {w[o] =w[o] ||function () {(w[o].q = w[o].q || []).push(arguments);};(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);js.id = o;js.src = f;js.async = 1;fjs.parentNode.insertBefore(js, fjs);})(window, document, 'script', '_hw', 'https://files.withcherry.com/widgets/widget.js');_hw('init',{debug: false,variables: {slug: 'uniquecherryslug',name: 'Your Business Name',},styles: {primaryColor: '#00c37d',secondaryColor: '#00c37d10',fontFamily: 'Open Sans',},},['all', 'hero', 'howitworks', 'testimony', 'faq', 'calculator']);"
                      }
                    />
                    <div id={"all"} />
                  </div>
                </React.Fragment>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 26764 }) {
      title
      seoTitle
      description
    }
  }
`;
